<script>
import _ from 'lodash'
import global from '@/utils/global'
import { mapState } from 'vuex'
import OnlyUpload from '@/components/OnlyUpload'
import PersonalDetailsItem from '@/components/PersonalDetailsItem'
// import VuePhoneNumberInput from 'vue-phone-number-input'
// import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import VuePhoneNumberInput from '@/components/phoneNumber'

import GetAllGeoCountries from '@/components/GetAllGeoCountries'
import AddressAutocomplete from '@/components/AddressAutocomplete'
import { getStatesApi, getAllGeoCountriesApi } from '@/api/application'
import {
  preparationApi, onShowPassportChangeApi, onShowCreditorBankCardChangeApi
  , onShowDriversLicenceChangeApi, onPassportCountryChangeApi, uploadPassportApi,
  uploadBankOrDreditCardApi, uploadCitizenShipCertApi, uploadBirthCertificateApi,
  uploadDriversLicenceeApi, continueApi, saveFormDataApi, removeDocumentApi, uploadDriversLicenceeBackApi
} from '@/api/PersonalDetailsInternational'
// import PubSubHub from '@/views/Edit/PubSubHub.vue'
import DriversLicenceTips from '@/views/Edit/PersonalDetailsInternational/Modals/driverLicenceTips.vue'
import SingleNameTips from '@/views/Edit/PersonalDetailsInternational/Modals/singleNameTips.vue'

export default {
  name: 'PersonalDetailsInternational',
  components: {
    SingleNameTips,
    DriversLicenceTips,
    OnlyUpload,
    PersonalDetailsItem,
    VuePhoneNumberInput,
    GetAllGeoCountries,
    AddressAutocomplete
  },
  inject: ['OnApplicantChange', 'ContinueSetSession', 'OnFormDataSave', 'SaveFormDataSetSession', 'SetApplicant'],
  provide() {
    return {
      OnItemAddNew: this.OnOtherNameAddNew,
      OnItemRemoved: this.OnOtherNameRemoved,
      getAddFormList: this.getAddFormList
    }
  },
  data() {
    const validateMobilePhone = (rule, value, callback) => {
      if (value) {
        if (this.isValid) {
          callback()
        } else {
          callback(new Error('Please enter a valid phone number'))
        }
      } else {
        callback(new Error('Require field!'))
      }
    }
    const validateSearch = (rule, value, callback) => {
      if (value !== '') {
        if (value === this.displayLine1) {
          callback()
        } else {
          callback(new Error('Please select an address from the list.'))
        }
      } else {
        callback(new Error('Required field!'))
      }
    }
    const validateOtherNames = (rule, value, callback) => {
      if (value) {
        if (value === 'Yes') {
          if (this.otherNames.length === 1 && !this.status && this.otherNamesValidator) {
            const data = this.otherNames[0]
            if (data.type || data.firstName || data.lastName || data.nameChangeDate || data.middleNames) {
              callback()
            } else {
              callback(new Error('Please provide at least one Other Name.'))
            }
          } else {
            callback()
          }
        } else {
          callback()
        }
      } else {
        callback(new Error('Please select a value'))
      }
    }

    const validateFirstName = (rule, value, callback) => {
      if (this.ruleForm.isSingleNameOnly) {
        callback()
      } else {
        if (value.trim() === '') {
          callback(new Error('Required field!'))
        } else {
          callback()
        }
      }
    }

    const validateLastName = (rule, value, callback) => {
      if (value.trim() === '') {
        callback(new Error('Required field!'))
      } else {
        callback()
      }
    }

    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6
        }
      },
      isValid: false,
      radioButtonValues: {},
      showOtherNames: false,
      otherNames: [],
      manualAddressEntry: {},
      isManualAddress: false,
      selectedCountryCode: '',
      deletedOtherNames: [],
      driversLicenceOrderApplicantDocuments: [],
      driversLicenceBackOrderApplicantDocuments: [],
      passportOrderApplicantDocuments: [],
      creditOrBankCardOrderApplicantDocuments: [],
      australianStates: [],
      birthCertificateOrderApplicantDocuments: [],
      geoCountries: [],
      showDriversLicenceTips: false,
      showSingleNameTips: false,
      isSingleNameOnlyEnabled: false,
      ruleForm: {
        driversLicence: null,
        otherNames: null,
        passport: null,
        creditOrBankCard: null,
        selectedCardType: '',
        selectedDriversLicenseCountryCode: null
      },
      rules: {
        firstName: [
          { validator: validateFirstName, trigger: 'submit' }
        ],
        // lastName: [{ required: true, message: 'Required field!', trigger: 'blur' }],
        lastName: [{ validator: validateLastName, trigger: 'submit' }],
        initialAddress: [{ required: true, validator: validateSearch, trigger: 'blur' }],
        email: [
          { required: true, message: 'Required field!', trigger: 'blur' },
          { type: 'email', message: 'Email is not valid!', trigger: 'blur' }
        ],
        dateOfBirth: [{ required: true, message: 'Required field!', trigger: 'blur' }],
        gender: [{ required: true, message: 'Please select a value', trigger: 'blur' }],
        driversLicence: [{ required: true, message: 'Please select a value', trigger: 'blur' }],
        driversLicenceNumber: [{ required: true, message: 'Required field!', trigger: 'blur' }],
        driversLicenceCardNumber: [{ required: true, message: 'Required field!', trigger: 'blur' }],
        selectedDriversLicenseCountryCode: [{ required: true, message: 'Required field!', trigger: 'change' }],
        driversLicenceState: [{ required: true, message: 'Required field!', trigger: 'change' }],
        creditOrBankCard: [{ required: true, message: 'Please select a value', trigger: 'change' }],
        passport: [{ required: true, message: 'Please select a value', trigger: 'blur' }],
        passportNumber: [{ required: true, message: 'Required field!', trigger: 'blur' }],
        passportCountryCode: [{ required: true, message: 'Required field!', trigger: 'change' }],
        otherNames: [{ required: true, validator: validateOtherNames, trigger: 'change' }],
        phone: [{ required: true, validator: validateMobilePhone, trigger: 'change' }],
        birthState: [{ required: true, message: 'Require field!', trigger: 'change' }],
        birthSuburb: [{ required: true, message: 'Require field!', trigger: 'change' }]
      },
      status: false,
      phoneFormat: {},
      displayLine1: '',
      numberShow: false,
      allLoading: false,
      otherNamesValidator: false,
      showDriverLicenseAdditionalInfo: false
    }
  },
  computed: {
    ...mapState({
      introduction: state => state.section.introduction,
      sectionTitle: state => state.section.sectionTitle,
      preparation: state => state.preparation.preparation,
      endAllRequests: state => state.portCount.endAllRequests
    }),
    Session() {
      return this.preparation.session
    },
    BlockName() {
      return this.preparation.session.current.formSection.blockName
    },
    ApplicantId() {
      return this.preparation.session.current.applicantId
    },
    Applicant() {
      return this.preparation.applicant
    },
    IsManualAddressShow() {
      return !this.isManualAddress
    },
    initialAddress() {
      return (this.Applicant.birthSuburb || '') + ((this.Applicant.birthState && this.Applicant.birthState !== 'OVERSEAS') ? (', ' + this.Applicant.birthState) : '') +
        ((this.Applicant.birthCountry && (this.Applicant.birthCountry ? this.Applicant.birthCountry.toUpperCase() : '') !== 'AUSTRALIA') ? (', ' + this.Applicant.birthCountry) : '') || ''
    },
    imgcanDelete() {
      // (not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100) or Session.Current.IsAdmin
      return (!this.Session.current.isSubmitted || this.Session.current.formSection.completePercent < 100) || this.Session.current.isAdmin
    },
    imgcanDelete2() {
      // not Session.Current.IsSubmitted or Session.Current.IsAdmin
      return !this.Session.current.isSubmitted || this.Session.current.isAdmin
    },
    creditOrBankCardDisabled() {
      return this.creditOrBankCardOrderApplicantDocuments.length > 0
    },
    allDisabled() {
      // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return !(
        !this.Session.current.isSubmitted ||
        this.Session.current.formSection.completePercent < 100 ||
        this.Session.current.isAdmin
      )
    },
    ContinueBtn() {
      // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return (!this.Session.current.isSubmitted ||
        this.Session.current.formSection.completePercent < 100 ||
        this.Session.current.isAdmin)
    }
  },
  watch: {
    isSingleNameOnlyEnabled(val) {
      this.ruleForm.isSingleNameOnly = val
      if (val) {
        this.ruleForm.firstName = this.ruleForm.lastName
        this.ruleForm.middleNames = ''
      } else {
        this.ruleForm.firstName = ''
        this.ruleForm.middleNames = ''
      }
      this.applicantChange()
    }
  },
  created() {
    this.Preparation()
  },
  methods: {
    async Preparation() {
      // if (PubSubHub !== null && PubSubHub.client.state === 'Connected') {
      //   await PubSubHub.client.send('UpdateApplicantWebblock', 'PersonalDetailsInternational')
      // }
      this.allLoading = true
      const params = {
        session: this.Session,
        applicantId: this.ApplicantId,
        applicant: this.Applicant
      }

      await preparationApi(params).then(async res => {
        const { data } = res
        this.radioButtonValues = data.radioButtonValues
        this.ruleForm.driversLicence = this.radioButtonValues.driversLicence
        this.ruleForm.otherNames = this.radioButtonValues.otherNames
        this.ruleForm.passport = this.radioButtonValues.passport
        this.ruleForm.creditOrBankCard = this.radioButtonValues.creditOrBankCard
        this.ruleForm.initialAddress = this.initialAddress
        this.displayLine1 = this.initialAddress
        const applicant = this.Applicant
        if (applicant.selectedDriversLicenseCountryCode) {
          delete applicant.selectedDriversLicenseCountryCode
        }
        this.ruleForm = { ...applicant, ...this.ruleForm }
        this.ruleForm.phone = this.Applicant.phone
        this.isSingleNameOnlyEnabled = this.ruleForm.isSingleNameOnly
        this.ruleForm.selectedDriversLicenseCountryCode = this.Applicant.driversLicenseCountryCode
        this.ruleForm.dateOfBirth = this.ruleForm.dateOfBirth === '1900-01-01T00:00:00' ? null : this.ruleForm.dateOfBirth

        this.showOtherNames = data.showOtherNames
        this.otherNames = data.otherNames
        this.manualAddressEntry = data.manualAddressEntry || {}
        this.isManualAddress = data.isManualAddress
        this.selectedCountryCode = data.selectedCountryCode
        this.deletedOtherNames = data.otherNames || []

        this.driversLicenceOrderApplicantDocuments = data.driversLicenceOrderApplicantDocuments
        this.driversLicenceBackOrderApplicantDocuments = data.driversLicenceBackOrderApplicantDocuments
        this.passportOrderApplicantDocuments = data.passportOrderApplicantDocuments
        this.creditOrBankCardOrderApplicantDocuments = data.creditOrBankCardOrderApplicantDocuments
        this.birthCertificateOrderApplicantDocuments = data.birthCertificateOrderApplicantDocuments
        this.showDriverLicenseAdditionalInfo = data.showDriverLicenseAdditionalInfo

        this.australianStates = data.australianStates
        this.geoCountries = data.countries

        if (!this.geoCountries.length) {
          await getAllGeoCountriesApi().then((res) => {
            const { data } = res
            this.geoCountries = data.geoCountries
          })
        }

        this.numberShow = true
        this.allLoading = false
      }).catch(() => {
        this.allLoading = false
      })
      window.scrollTo(0, 0)
    },

    isSingleNameOnlyChange(val) {
      this.ruleForm.isSingleNameOnly = val
      if (val) {
        this.ruleForm.firstName = this.ruleForm.lastName
        this.ruleForm.middleNames = ''
      } else {
        this.ruleForm.firstName = ''
        this.ruleForm.middleNames = ''
      }
      this.applicantChange()
    },

    update(allValue) {
      this.phoneFormat = allValue
      this.ruleForm.phone = allValue.formattedNumber
      this.isValid = allValue.isValid
    },

    phoneBlur() {
      this.$refs['ruleForm'].validateField(['phone'])
      if (this.isValid && this.ruleForm.phone !== this.Applicant.phone) {
        const applicant = JSON.parse(JSON.stringify(this.ruleForm))
        applicant.phone = this.phoneFormat.formattedNumber
        this.OnApplicantChange({ session: this.Session, applicant: applicant })
      }
    },

    async OnShowPassportChange(val) {
      this.radioButtonValues.driversLicence = this.ruleForm.driversLicence
      this.radioButtonValues.otherNames = this.ruleForm.otherNames
      this.radioButtonValues.passport = this.ruleForm.passport
      this.radioButtonValues.creditOrBankCard = this.ruleForm.creditOrBankCard

      // this.radioButtonValues.isPassportValid = true

      const session = this.Session
      session.personalDetails.hasPassport = this.radioButtonValues.passport

      const ruleForm = { ...this.ruleForm }
      const params = {
        session: session,
        applicant: ruleForm,
        radioButtonValues: this.radioButtonValues,
        passportOrderApplicantDocuments: this.passportOrderApplicantDocuments,
        formData: {
          otherNames: this.otherNames,
          showOtherNames: this.showOtherNames,
          radioButtonValues: this.radioButtonValues,
          manualAddressEntry: this.manualAddressEntry,
          isManualAddress: this.isManualAddress,
          selectedCountryCode: this.selectedCountryCode,
          deletedOtherNames: [],
          selectedDriversLicenseCountryCode: this.ruleForm.selectedDriversLicenseCountryCode
        }
      }
      delete params.applicant.driversLicence
      delete params.applicant.otherNames
      delete params.applicant.passport
      delete params.applicant.creditOrBankCard

      if (val === 'No') {
        params.applicant.passportNumber = ''
        params.applicant.passportCountry = ''
        params.applicant.passportCountryCode = ''
        params.applicant.passportCountryCode3 = ''

        this.ruleForm.passportNumber = ''
        this.ruleForm.passportCountry = ''
        this.ruleForm.passportCountryCode = ''
        this.ruleForm.passportCountryCode3 = ''
        this.passportOrderApplicantDocuments = []
      }

      await onShowPassportChangeApi(params).then(async res => {
        const { data } = res
        if (val === 'Yes') {
          this.passportOrderApplicantDocuments = []
          this.passportOrderApplicantDocuments = data.passportOrderApplicantDocuments || []
        }
        this.SetApplicant(data.applicant)
        this.SaveFormDataSetSession(data.session)
        // this.OnFormDataSave({ session: this.Session })
      })
      if (val === 'Yes') {
        this.OnPassportCountryChange({ countryCode: 'AU', countryName: 'Australia', isoAlpha3: 'AUS' })
      }
    },

    async OnShowCreditOrBankCardChange(val) {
      this.radioButtonValues.driversLicence = this.ruleForm.driversLicence
      this.radioButtonValues.otherNames = this.ruleForm.otherNames
      this.radioButtonValues.passport = this.ruleForm.passport
      this.radioButtonValues.creditOrBankCard = this.ruleForm.creditOrBankCard

      this.radioButtonValues.isBankCardValid = true

      const session = this.Session
      session.personalDetails.hasFirearmsLicence = this.radioButtonValues.creditOrBankCard

      const ruleForm = { ...this.ruleForm }
      const params = {
        session: session,
        applicant: ruleForm,
        radioButtonValues: this.radioButtonValues,
        formData: {
          otherNames: this.otherNames,
          showOtherNames: this.showOtherNames,
          radioButtonValues: this.radioButtonValues,
          manualAddressEntry: this.manualAddressEntry,
          isManualAddress: this.isManualAddress,
          selectedCountryCode: this.selectedCountryCode,
          deletedOtherNames: [],
          selectedDriversLicenseCountryCode: this.ruleForm.selectedDriversLicenseCountryCode,
          creditOrBankCardOrderApplicantDocuments: this.creditOrBankCardOrderApplicantDocuments

        }
      }
      delete params.applicant.driversLicence
      delete params.applicant.otherNames
      delete params.applicant.passport
      delete params.applicant.creditOrBankCard

      if (val === 'No') {
        // delete params.radioButtonValues
        // delete params.formData

        params.applicant.firearmsLicenceNumber = ''
        params.applicant.firearmsLicenceState = ''

        params.session.personalDetails.hasPassport = this.radioButtonValues.passport
        params.session.personalDetails.hasDriversLicence = this.radioButtonValues.driversLicence
        params.session.personalDetails.hasOtherNames = this.radioButtonValues.otherNames

        this.OnApplicantChange(params)
      }
      await onShowCreditorBankCardChangeApi(params).then(res => {
        const { data } = res
        this.creditOrBankCardOrderApplicantDocuments = data.creditOrBankCardOrderApplicantDocuments
        this.SaveFormDataSetSession(data.session)
        this.OnApplicantChange(params)
        this.SaveFormData()
      })
    },

    async OnShowDriversLicenceChange(val) {
      this.radioButtonValues.driversLicence = this.ruleForm.driversLicence
      this.radioButtonValues.otherNames = this.ruleForm.otherNames
      this.radioButtonValues.passport = this.ruleForm.passport
      this.radioButtonValues.creditOrBankCard = this.ruleForm.creditOrBankCard

      this.radioButtonValues.isDriversLicenceValid = true

      const session = this.Session
      session.personalDetails.hasDriversLicence = this.radioButtonValues.driversLicence

      const ruleForm = { ...this.ruleForm }
      const params = {
        session: session,
        applicant: ruleForm,
        radioButtonValues: this.radioButtonValues,
        formData: {
          otherNames: this.otherNames,
          showOtherNames: this.showOtherNames,
          radioButtonValues: this.radioButtonValues,
          manualAddressEntry: this.manualAddressEntry,
          isManualAddress: this.isManualAddress,
          selectedCountryCode: this.selectedCountryCode,
          deletedOtherNames: [],
          selectedDriversLicenseCountryCode: this.ruleForm.selectedDriversLicenseCountryCode,
          driversLicenceOrderApplicantDocuments: this.driversLicenceOrderApplicantDocuments,
          driversLicenceBackOrderApplicantDocuments: this.driversLicenceBackOrderApplicantDocuments
        }
      }
      delete params.applicant.driversLicence
      delete params.applicant.otherNames
      delete params.applicant.passport
      delete params.applicant.creditOrBankCard

      if (val === 'No') {
        params.applicant.driversLicenceNumber = ''
        params.applicant.driversLicenceCardNumber = ''
        params.applicant.driversLicenceState = ''
        params.applicant.driversLicenseCountry = ''
        params.applicant.driversLicenseCountryCode = ''
        params.applicant.driversLicenseCountryCode3 = ''

        this.ruleForm.driversLicenceNumber = ''
        this.ruleForm.driversLicenceCardNumber = ''
        this.ruleForm.driversLicenceState = ''
        this.ruleForm.selectedDriversLicenseCountryCode = ''

        params.session.personalDetails.hasPassport = this.radioButtonValues.passport
        params.session.personalDetails.hasDriversLicence = this.radioButtonValues.driversLicence
        params.session.personalDetails.hasOtherNames = this.radioButtonValues.otherNames

        this.driversLicenceOrderApplicantDocuments = []
        this.driversLicenceBackOrderApplicantDocuments = []
        // this.OnApplicantChange(params)
      }
      await onShowDriversLicenceChangeApi(params).then(res => {
        const { data } = res
        this.SaveFormDataSetSession(data.session)
        this.OnApplicantChange(params)
        this.SaveFormData()
        if (val === 'Yes') {
          this.driversLicenceOrderApplicantDocuments = data.driversLicenceOrderApplicantDocuments
          this.driversLicenceBackOrderApplicantDocuments = data.driversLicenceBackOrderApplicantDocuments
          this.OnDriversLicenseCountryChange({ countryCode: 'AU', countryName: 'Australia', isoAlpha3: 'AUS' })
        }
      })
    },

    async OnPassportCountryChange(data) {
      this.ruleForm.passportCountryCode = data.countryCode
      this.ruleForm.passportCountry = data.countryName
      this.ruleForm.passportCountryCode3 = data.isoAlpha3
      const applicant = this.Applicant
      applicant.passportCountryCode = data.countryCode
      applicant.passportCountry = data.countryName
      applicant.passportCountryCode3 = data.isoAlpha3
      const params = {
        session: this.Session,
        applicant: applicant,
        passportCountryCode: this.ruleForm.passportCountryCode
      }

      await onPassportCountryChangeApi(params).then(res => {
        const { data } = res
        if (res.data && res.data.session) {
          this.SaveFormDataSetSession(res.data.session)
        }
        this.passportOrderApplicantDocuments = data.passportOrderApplicantDocuments
        this.OnApplicantChange(params)
      })
    },

    async CloudinaryUpload_UploadResult_Passport(file) {
      if (!this.ruleForm.passportCountryCode) {
        this.$refs.ruleForm.validateField('passportCountryCode')
        return
      }
      const formData = new FormData()
      formData.append('OrderApplicantId', this.Session.current.orderApplicantId)
      formData.append('BlockName', this.BlockName)
      formData.append('PassportCountryCode', this.ruleForm.passportCountryCode)
      formData.append('file', file)
      await uploadPassportApi(formData).then(res => {
        const { data } = res
        this.passportOrderApplicantDocuments = data.passportOrderApplicantDocuments
      })
    },

    async CloudinaryUpload_UploadResult_BankorCreditCard(file) {
      const formData = new FormData()
      formData.append('OrderApplicantId', this.Session.current.orderApplicantId)
      formData.append('BlockName', this.BlockName)
      formData.append('HasPassport', this.ruleForm.hasPassport)
      formData.append('SelectedCardType', this.ruleForm.selectedCardType)
      formData.append('file', file)
      await uploadBankOrDreditCardApi(formData).then(res => {
        const { data } = res
        this.creditOrBankCardOrderApplicantDocuments = data.creditOrBankCardOrderApplicantDocuments
      })
    },

    async CloudinaryUpload_UploadResult_CitizenshipCert(file) {
      const formData = new FormData()
      formData.append('OrderApplicantId', this.Session.current.orderApplicantId)
      formData.append('BlockName', this.BlockName)
      formData.append('file', file)
      await uploadCitizenShipCertApi(formData).then(res => {
        const { data } = res
        this.birthCertificateOrderApplicantDocuments = data.birthCertificateOrderApplicantDocuments
      })
    },

    async CloudinaryUpload_UploadResult_BirthCertificate(file) {
      const formData = new FormData()
      formData.append('OrderApplicantId', this.Session.current.orderApplicantId)
      formData.append('BlockName', this.BlockName)
      formData.append('file', file)
      await uploadBirthCertificateApi(formData).then(res => {
        const { data } = res
        this.birthCertificateOrderApplicantDocuments = data.birthCertificateOrderApplicantDocuments
      })
    },

    async CloudinaryUpload_UploadResult_DriversLicence(file) {
      const formData = new FormData()
      formData.append('OrderApplicantId', this.Session.current.orderApplicantId)
      formData.append('BlockName', this.BlockName)
      formData.append('file', file)
      await uploadDriversLicenceeApi(formData).then(res => {
        const { data } = res
        this.driversLicenceOrderApplicantDocuments = data.driversLicenceOrderApplicantDocuments
      })
    },

    async CloudinaryUpload_UploadResult_DriversLicenceBack(file) {
      const formData = new FormData()
      formData.append('OrderApplicantId', this.Session.current.orderApplicantId)
      formData.append('BlockName', this.BlockName)
      formData.append('file', file)
      await uploadDriversLicenceeBackApi(formData).then(res => {
        const { data } = res
        this.driversLicenceBackOrderApplicantDocuments = data.driversLicenceBackOrderApplicantDocuments
      })
    },

    async Document_Remove(item) {
      const params = {
        orderApplicantDocumentId: item.id,
        blockName: this.BlockName,
        applicant: this.ruleForm,
        OrderApplicantId: this.Session.current.orderApplicantId,
        session: this.Session
      }
      await removeDocumentApi(params).then(res => {
        const { data } = res
        this.birthCertificateOrderApplicantDocuments = data.birthCertificateOrderApplicantDocuments
        this.driversLicenceOrderApplicantDocuments = data.driversLicenceOrderApplicantDocuments
        this.driversLicenceBackOrderApplicantDocuments = data.driversLicenceBackOrderApplicantDocuments
        this.creditOrBankCardOrderApplicantDocuments = data.creditOrBankCardOrderApplicantDocuments
        this.passportOrderApplicantDocuments = data.passportOrderApplicantDocuments
        this.SaveFormDataSetSession(res.data.session)
      })
    },
    OnBirthSuburbAutoCompleteManualEntry(Search) {
      this.displayLine1 = ''
      this.selectedCountryCode = this.manualAddressEntry.countryCode === '' ? 'AU' : this.manualAddressEntry.countryCode
      this.isManualAddress = true
      // this.manualAddressEntry.suburb = Search
      this.$set(this.manualAddressEntry, 'suburb', Search)
      this.manualAddressEntry.country = !this.manualAddressEntry.country ? 'Australia' : this.manualAddressEntry.country
      this.manualAddressEntry.countryCode = !this.manualAddressEntry.countryCode ? 'AU' : this.manualAddressEntry.countryCode
      this.ruleForm.country = !this.manualAddressEntry.country ? 'Australia' : this.manualAddressEntry.country
      this.ruleForm.birthCountry = !this.manualAddressEntry.country ? 'Australia' : this.manualAddressEntry.country
      this.ruleForm.birthCountryCode = !this.manualAddressEntry.countryCode ? 'AU' : this.manualAddressEntry.countryCode
      this.manualAddressEntry.suburb = this.ruleForm.birthSuburb
      this.manualAddressEntry.state = this.ruleForm.birthState
      this.OnApplicantChange({
        session: this.Session,
        applicant: this.ruleForm
      })
      this.SaveFormData()
    },
    OnBirthSuburbNoSelectionMade() {
      this.ruleForm.birthSuburb = ''
      this.ruleForm.birthState = ''
      this.ruleForm.birthCountry = ''
      this.ruleForm.birthCountryCode = ''
      this.OnApplicantChange({
        session: this.Session,
        applicant: this.ruleForm
      })
    },
    OnBirthSuburbSearchDetailReady(AddressDetail, displayLine1) {
      this.displayLine1 = displayLine1
      this.ruleForm.birthSuburb = AddressDetail.suburb ? AddressDetail.suburb : AddressDetail.addressLine1
      this.ruleForm.birthState = AddressDetail.state
      this.ruleForm.birthCountry = AddressDetail.country
      this.ruleForm.birthCountryCode = AddressDetail.countryCode
      this.ruleForm.birthState = this.ruleForm.birthCountryCode === 'AU' ? this.ruleForm.birthState : 'OVERSEAS'
      this.ruleForm.isBirthManual = false
      // this.ruleForm.birthCountryCode3 = GetCountry(AddressDetail.CountryCode).IsoAlpha3
      this.OnApplicantChange({
        session: this.Session,
        applicant: this.ruleForm
      })
      this.$refs.ruleForm.validateField('initialAddress')
    },

    async OnDriversLicenseCountryChange(data) {
      this.ruleForm.selectedDriversLicenseCountryCode = data.countryCode
      this.ruleForm.driversLicenseCountry = data.countryName
      this.ruleForm.driversLicenseCountryCode = data.countryCode
      this.ruleForm.driversLicenseCountryCode3 = data.isoAlpha3

      if (this.ruleForm.selectedDriversLicenseCountryCode.toUpperCase() === 'AU') {
        this.ruleForm.driversLicenceState = ''
        await getStatesApi({ countryCode: this.ruleForm.selectedDriversLicenseCountryCode }).then(res => {
          const { data } = res
          this.australianStates = data.states
        })
      } else {
        this.ruleForm.driversLicenceState = 'OVERSEAS'
      }
      this.OnApplicantChange({
        session: this.Session,
        applicant: this.ruleForm
      })
    },

    async OnManualCountryChange(data) {
      this.ruleForm.country = data.countryCode
      this.ruleForm.birthCountry = data.countryName
      this.ruleForm.birthCountryCode = data.countryCode
      this.ruleForm.birthCountryCode3 = data.isoAlpha3
      this.manualAddressEntry.country = data.countryName
      this.manualAddressEntry.countryCode = data.countryCode
      this.manualAddressEntry.countryCode3 = data.isoAlpha3
      if (this.manualAddressEntry.countryCode.toUpperCase() === 'AU') {
        this.manualAddressEntry.state = ''
        this.ruleForm.birthState = ''
        await getStatesApi({ countryCode: this.manualAddressEntry.countryCode }).then(res => {
          const { data } = res
          this.australianStates = data.states
        })
      } else {
        this.ruleForm.birthState = 'OVERSEAS'
        this.manualAddressEntry.state = 'OVERSEAS'
      }
      this.SaveFormData()
      this.OnApplicantChange({
        session: this.Session,
        applicant: this.ruleForm
      })
    },

    OnManualStateChange() {
      this.manualAddressEntry.state = this.ruleForm.birthState
      this.OnApplicantChange({
        session: this.Session,
        applicant: this.ruleForm
      })
    },

    OnManualSuburbChange: _.debounce(
      function() {
        this.manualAddressEntry.suburb = this.ruleForm.birthSuburb
        this.OnApplicantChange({
          session: this.Session,
          applicant: this.ruleForm
        })
      }, global.debounceTime),

    async OnOtherNameAddNew(list) {
      this.otherNames = list
      this.otherNames.forEach(item => {
        item.applicantId = this.ApplicantId
      })
      const params = {
        session: this.Session,
        applicantId: this.ApplicantId,
        formData: {
          otherNames: this.otherNames,
          showOtherNames: this.showOtherNames,
          radioButtonValues: this.radioButtonValues,
          manualAddressEntry: this.manualAddressEntry,
          isManualAddress: this.isManualAddress,
          selectedCountryCode: this.selectedCountryCode,
          deletedOtherNames: []
        }
      }
      await saveFormDataApi(params).then(res => {
        if (res.data && res.data.session) {
          this.SaveFormDataSetSession(res.data.session)
        }
      })
    },

    async OnOtherNameRemoved(item, list) {
      this.otherNames = list
      this.otherNames.forEach(item => {
        item.applicantId = this.ApplicantId
      })
      this.deletedOtherNames = [item]
      this.deletedOtherNames[0].applicantId = this.ApplicantId
      const params = {
        session: this.Session,
        applicantId: this.ApplicantId,
        formData: {
          otherNames: this.otherNames,
          showOtherNames: this.showOtherNames,
          radioButtonValues: this.radioButtonValues,
          manualAddressEntry: this.manualAddressEntry,
          isManualAddress: this.isManualAddress,
          selectedCountryCode: this.selectedCountryCode,
          deletedOtherNames: this.deletedOtherNames
        }
      }
      await saveFormDataApi(params).then(res => {
        if (res.data && res.data.session) {
          this.SaveFormDataSetSession(res.data.session)
        }
      })
    },

    getAddFormList(list, status) {
      this.otherNames = list
      this.otherNames.forEach(item => {
        item.applicantId = this.ApplicantId
      })
      this.status = status
    },

    async SaveFormData() {
      const params = {
        session: this.Session,
        applicantId: this.ApplicantId,
        formData: {
          otherNames: this.otherNames,
          showOtherNames: this.showOtherNames,
          radioButtonValues: this.radioButtonValues,
          manualAddressEntry: this.manualAddressEntry,
          isManualAddress: this.isManualAddress,
          selectedCountryCode: this.selectedCountryCode,
          deletedOtherNames: []
        }
      }
      await saveFormDataApi(params).then(res => {
        if (res.data && res.data.session) {
          this.SaveFormDataSetSession(res.data.session)
        }
      })
    },

    applicantChange: _.debounce(
      function() {
        const applicant = JSON.parse(JSON.stringify(this.ruleForm))
        this.OnApplicantChange({ session: this.Session, applicant: applicant })
      }, global.debounceTime),

    Continue(formName) {
      this.otherNamesValidator = true
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          if (this.ruleForm.driversLicence === 'Yes' && this.driversLicenceOrderApplicantDocuments.length < 1) {
            return this.$message.error('Please attach a legible copy of the FRONT of your Driver\'s Licence before continuing')
          }

          if (this.showDriverLicenseAdditionalInfo) {
            if (this.ruleForm.driversLicence === 'Yes' && this.driversLicenceBackOrderApplicantDocuments.length < 1) {
              return this.$message.error('Please attach a legible copy of the BACK of your Driver\'s Licence before continuing')
            }
          }

          if (this.ruleForm.passport === 'Yes' && this.passportOrderApplicantDocuments.length < 1) {
            return this.$message.error('Please attach a copy of your Passport before continuing')
          }

          if (this.ruleForm.passport === 'No' && !this.ruleForm.selectedCertificateType && this.birthCertificateOrderApplicantDocuments.length < 1) {
            return this.$message.error('Please attach a copy of your Birth Certificate or Citizenship Certificate before continuing')
          }

          if (this.ruleForm.passport === 'No' && this.birthCertificateOrderApplicantDocuments.length < 1) {
            if (this.ruleForm.selectedCertificateType === 'Birth') {
              return this.$message.error('Please attach a copy of your Birth Certificate before continuing')
            } else if (this.ruleForm.selectedCertificateType === 'Citizenship') {
              return this.$message.error('Please attach a copy of your Citizenship Certificate before continuing')
            }
          }

          if (this.ruleForm.creditOrBankCard === 'Yes' && !this.ruleForm.selectedCardType && this.creditOrBankCardOrderApplicantDocuments.length < 1) {
            return this.$message.error('Please attach a copy of your Credit Card or Bank Card before continuing')
          }

          if (this.ruleForm.creditOrBankCard === 'Yes' && this.creditOrBankCardOrderApplicantDocuments.length < 1) {
            if (this.ruleForm.selectedCardType === 'Credit') {
              return this.$message.error('Please attach a copy of your Credit Card before')
            } else if (this.ruleForm.selectedCardType === 'Bank') {
              return this.$message.error('Please attach a copy of your Bank Card before continuing')
            }
          }

          if (this.ruleForm.otherNames === 'Yes') {
            this.$refs.PersonalDetailsItem.handleSure()
            if (!this.status) {
              return
            }
          }
          this.status = false

          this.radioButtonValues.driversLicence = this.ruleForm.driversLicence
          this.radioButtonValues.creditOrBankCard = this.ruleForm.creditOrBankCard
          this.radioButtonValues.otherNames = this.ruleForm.otherNames
          this.radioButtonValues.passport = this.ruleForm.passport
          const session = this.Session
          const ruleForm = { ...this.ruleForm }
          ruleForm.phone = this.phoneFormat.formattedNumber
          const params = {
            applicant: ruleForm,
            manualAddressEntry: this.manualAddressEntry,
            radioButtonValues: this.radioButtonValues,
            formData: {
              otherNames: this.otherNames,
              showOtherNames: this.showOtherNames,
              radioButtonValues: this.radioButtonValues,
              manualAddressEntry: this.manualAddressEntry,
              selectedCountryCode: this.selectedCountryCode,
              deletedOtherNames: this.radioButtonValues.otherNames === 'No' ? this.deletedOtherNames : [],
              isManualAddress: this.isManualAddress,
              selectedDriversLicenseCountryCode: this.ruleForm.selectedDriversLicenseCountryCode
            },
            session: session,
            otherNames: this.otherNames,
            deletedOtherNames: this.radioButtonValues.otherNames === 'No' ? this.deletedOtherNames : [],
            driversLicenceOrderApplicantDocuments: this.driversLicenceOrderApplicantDocuments,
            driversLicenceBackOrderApplicantDocuments: this.driversLicenceBackOrderApplicantDocuments,
            birthCertificateOrderApplicantDocuments: this.birthCertificateOrderApplicantDocuments,
            creditOrBankCardOrderApplicantDocuments: this.creditOrBankCardOrderApplicantDocuments,
            passportOrderApplicantDocuments: this.passportOrderApplicantDocuments,
            isManualAddress: this.isManualAddress,
            showDriverLicenseAdditionalInfo: this.showDriverLicenseAdditionalInfo
          }
          delete params.applicant.driversLicence
          delete params.applicant.firearmsLicence
          delete params.applicant.otherNames
          delete params.applicant.passport

          await continueApi(params).then(res => {
            this.ContinueSetSession(res.data.session)
          })
        } else {
          this.otherNamesValidator = false
        }
      })
    },
    OnShowOtherNamesChange(val) {
      if (val === 'No') {
        this.otherNames = []
      }
      this.SaveFormData()
    },

    getImgcanDelete(item) {
      // (PassportOrderApplicantDocuments.List.Current.UploadedBlockName = Session.Current.FormSection.BlockName
      // and not Session.Current.IsSubmitted) or Session.Current.IsAdmin
      return (item.uploadedBlockName === this.Session.current.formSection.blockName &&
        !this.Session.current.isSubmitted) || this.Session.current.isAdmin
    }
  }
}
</script>

<template>
  <div v-loading="allLoading" class="PersonalDetailsInternational">
    <div class="section-header">{{ sectionTitle }}</div>
    <div class="mb-10 f-s-14" v-html="introduction" />
    <el-divider />
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      class="demo-ruleForm"
      label-width="50%"
      label-position="top"
      :disabled="allDisabled"
    >
      <el-row v-if="!ruleForm.isSingleNameOnly" :gutter="20">
        <el-col
          :xs="24" :sm="24" :md="12" :lg="12"
          :xl="12"
        >
          <el-form-item prop="firstName">
            <p class="label"><span class="color-red">*</span> Given Name (current legal name)</p>
            <el-input
              v-model="ruleForm.firstName" label="Given Name" :maxlength="100"
              :disabled="ruleForm.isSingleNameOnly" @input="applicantChange"
              @keyup.native="ruleForm.firstName = ruleForm.firstName.replace(/[^a-zA-Z\s'-]/g,'')"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xs="24" :sm="24" :md="12" :lg="12"
          :xl="12"
        >
          <el-form-item prop="lastName">
            <p class="label"><span class="color-red">*</span> Family Name (current legal name)</p>
            <el-input
              v-model="ruleForm.lastName" label="Family Name" :maxlength="100" @input="applicantChange"
              @keyup.native="ruleForm.lastName = ruleForm.lastName.replace(/[^a-zA-Z\s'-]/g,'')"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row v-else :gutter="20">
        <el-col
          :xs="24" :sm="24" :md="12" :lg="12"
          :xl="12"
        >
          <el-form-item prop="lastName">
            <p class="label"><span class="color-red">*</span> Current legal name</p>
            <el-input
              v-model="ruleForm.lastName" label="Current legal name" :maxlength="100"
              @input="applicantChange" @keyup.native="ruleForm.lastName = ruleForm.lastName.replace(/[^a-zA-Z\s'-]/g,'')"
            />
            <a
              style="color: #206CAA; font-size:13px; font-weight: bold; cursor: help;"
              @click="showSingleNameTips = true"
            ><i class="fa fa-question-circle" /> I am not legally known by single name only? </a>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item v-if="!ruleForm.isSingleNameOnly" prop="middleNames">
        <p class="label">Middle Name(s) (current legal names)</p>
        <el-input
          v-model="ruleForm.middleNames" label="Middle Name(s) (current legal names)" :maxlength="100"
          :disabled="ruleForm.isSingleNameOnly" @input="applicantChange"
          @keyup.native="ruleForm.middleNames = ruleForm.middleNames.replace(/[^a-zA-Z\s'-]/g,'')"
        />
        <a style="color: #206CAA; font-size:13px; font-weight: bold; cursor: help;" @click="showSingleNameTips = true"><i class="fa fa-question-circle" /> I am legally known by single name only? </a>
      </el-form-item>

      <el-row :gutter="20">
        <el-col
          :xs="24" :sm="24" :md="12" :lg="12"
          :xl="12"
        >
          <el-form-item prop="email">
            <label class="label">* Email
              <el-input v-model="ruleForm.email" disabled />
            </label>
          </el-form-item>
        </el-col>
        <el-col
          :xs="24" :sm="24" :md="12" :lg="12"
          :xl="12"
        >
          <el-form-item prop="phone">
            <label class="label">* Contact Phone Number
              <el-tooltip
                class="item" effect="dark" content="Select the country and enter the phone number"
                placement="top" aria-describedby="phone-input"
              >
                <i class="el-icon-info" />
              </el-tooltip>
              <!-- <VuePhoneNumberInput
              ref="vuePhoneNumberInput"
              v-model="ruleForm.phone"
              :error="error"
              :valid-color="validColor"
              :color="color"
              :no-country-selector="noCountrySelector"
              :default-country-code="defaultCountryCode"
              :preferred-countries="preferredCountries"
              :disabled="disabled"
              :clearable="clearable"
              :show-code-on-list="showCodeOnList"
              :no-flags="noFlags"
              :border-radius="borderRadius"
              :no-example="noExample"
              :translations="translations"
              @update="update"
              @phone-number-blur="phoneBlur"
            /> -->
              <VuePhoneNumberInput
                v-if="numberShow"
                id="phone-input"
                :number="ruleForm.phone"
                :disabled="allDisabled"
                @update="update"
                @phoneBlur="phoneBlur"
              />
            </label>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col
          :xs="24" :sm="24" :md="12" :lg="12"
          :xl="12"
        >
          <el-form-item prop="dateOfBirth">
            <label class="label">* Date of Birth
              <el-date-picker
                v-model="ruleForm.dateOfBirth"
                value-format="yyyy-MM-dd"
                format="dd/MM/yyyy"
                :picker-options="pickerOptions"
                placeholder="DD/MM/YYYY"
                type="date"
                @change="applicantChange"
              />
            </label>
          </el-form-item>
        </el-col>
        <el-col
          :xs="24" :sm="24" :md="12" :lg="12"
          :xl="12"
        >
          <el-form-item v-if="IsManualAddressShow" prop="initialAddress">
            <label class="label">* Place of Birth
              <AddressAutocomplete
                :initialaddress.sync="ruleForm.initialAddress"
                :placeholder="'Start typing and pause to select from the list correct'"
                :use-google="true"
                :google-types="'(cities)'"
                from="PersonalDetailsInternational"
                @OnDetailReady="OnBirthSuburbSearchDetailReady"
                @OnManualEntry="OnBirthSuburbAutoCompleteManualEntry"
                @OnNoSelectionMade="OnBirthSuburbNoSelectionMade"
              />
            </label>
          </el-form-item>
          <div v-if="!IsManualAddressShow">
            <el-form-item
              :rules="[
                { required: true, message: 'Required'},
              ]"
            >
              <label class="label">* Place of Birth Country
                <GetAllGeoCountries
                  :defalut-value.sync="manualAddressEntry.countryCode"
                  :geo-countries="geoCountries"
                  @getCountryData="OnManualCountryChange"
                />
              </label>
            </el-form-item>
            <el-form-item v-if="manualAddressEntry.countryCode === 'AU'" prop="birthState">
              <label class="label">* Place of Birth State
                <el-select
                  v-model="ruleForm.birthState" popper-class="dropdownClass" style="width:100%"
                  @change="OnManualStateChange"
                >
                  <el-option
                    v-for="item in australianStates" :key="item.adminCodes1.ISO3166_2"
                    :label="item.adminCodes1.ISO3166_2" :value="item.adminCodes1.ISO3166_2"
                  />
                </el-select>
              </label>
            </el-form-item>
            <el-form-item prop="birthSuburb">
              <label class="label">* Place of Birth Suburb
                <el-input v-model="ruleForm.birthSuburb" @input="OnManualSuburbChange" />
              </label>
            </el-form-item>
          </div>
        </el-col>
      </el-row>

      <el-form-item prop="gender">
        <label class="label">
          <div style="margin-bottom: 5px;">* Gender</div>
          <el-radio-group v-model="ruleForm.gender" @change="applicantChange">
            <el-radio label="Male">Male</el-radio>
            <el-radio label="Female">Female</el-radio>
            <el-radio label="Other">Indeterminate / Intersex / Unspecified</el-radio>
          </el-radio-group>
        </label>
      </el-form-item>

      <el-divider />

      <el-form-item prop="driversLicence">
        <el-row :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="24" :lg="16"
            :xl="16" class="label"
          >
            <span class="color-red">*</span> Do you have a Current Driver's Licence?
          </el-col>
          <el-col
            :xs="24" :sm="24" :md="24" :lg="8"
            :xl="8"
          >
            <el-radio-group v-model="ruleForm.driversLicence" @change="OnShowDriversLicenceChange">
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </el-col>
        </el-row>
      </el-form-item>

      <template v-if="ruleForm.driversLicence==='Yes'">
        <el-row :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12"
          >
            <el-form-item prop="selectedDriversLicenseCountryCode">
              <label class="label">* Driver's Licence Country
                <GetAllGeoCountries
                  :defalut-value.sync="ruleForm.selectedDriversLicenseCountryCode"
                  :geo-countries="geoCountries"
                  @getCountryData="OnDriversLicenseCountryChange"
                />
              </label>
            </el-form-item>
          </el-col>
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12"
          >
            <el-form-item v-if="ruleForm.selectedDriversLicenseCountryCode === 'AU'" prop="driversLicenceState">
              <label class="label">* Driver's Licence State
                <el-select
                  v-model="ruleForm.driversLicenceState" popper-class="dropdownClass" style="width:100%"
                  @change="applicantChange"
                >
                  <el-option
                    v-for="item in australianStates" :key="item.adminCodes1.ISO3166_2"
                    :label="item.adminCodes1.ISO3166_2" :value="item.adminCodes1.ISO3166_2"
                  />
                </el-select>
              </label>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="ruleForm.driversLicenceState!=='' && ruleForm.driversLicenceState!==null" :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12"
          >
            <el-form-item prop="driversLicenceNumber">
              <label class="label">* Driver's Licence Number
                <el-input v-model="ruleForm.driversLicenceNumber" @input="applicantChange" />
              </label>
            </el-form-item>
          </el-col>
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12"
          >
            <el-form-item
              v-if="ruleForm.selectedDriversLicenseCountryCode === 'AU' && showDriverLicenseAdditionalInfo"
              prop="driversLicenceCardNumber"
            >
              <label class="label">* Driver's Licence Card Number
                <el-input v-model="ruleForm.driversLicenceCardNumber" @input="applicantChange" />
              </label>
            </el-form-item>
          </el-col>
          <el-col
            v-if="ruleForm.selectedDriversLicenseCountryCode === 'AU'" :xs="24" :sm="24" :md="24"
            :lg="16" :xl="16"
            class="label"
            style="margin-top: -10px; margin-bottom: 20px;"
          >
            <a style="color: #206CAA; font-weight: bold; cursor: help;" @click="showDriversLicenceTips = true"><i
              class="fa fa-question-circle"
            /> Where can I find my Driver Licence Number and Card Number?</a>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12"
          >
            <p class="label f-w-600"><span class="color-red">*</span> Please attach a legible copy of the FRONT of your
              Driver’s Licence</p>
            <el-form-item prop="driversLicenceOrderApplicantDocuments">
              <OnlyUpload
                v-if="driversLicenceOrderApplicantDocuments.length<1 && imgcanDelete"
                @Document_Upload="CloudinaryUpload_UploadResult_DriversLicence"
              />
              <OnlyUpload
                v-for="item in driversLicenceOrderApplicantDocuments"
                :key="item.id"
                :img-item="item"
                :can-delete="imgcanDelete"
                @Document_Upload="CloudinaryUpload_UploadResult_DriversLicence"
                @Document_Remove="Document_Remove"
              />
            </el-form-item>
          </el-col>
          <el-col
            v-if="showDriverLicenseAdditionalInfo" :xs="24" :sm="24" :md="12"
            :lg="12"
            :xl="12"
          >
            <p class="label f-w-600"><span class="color-red">*</span> Please attach a legible copy of the BACK of your
              Driver’s Licence</p>
            <el-form-item prop="driversLicenceBackOrderApplicantDocuments">
              <OnlyUpload
                v-if="driversLicenceBackOrderApplicantDocuments.length<1 && imgcanDelete"
                @Document_Upload="CloudinaryUpload_UploadResult_DriversLicenceBack"
              />
              <OnlyUpload
                v-for="item in driversLicenceBackOrderApplicantDocuments"
                :key="item.id"
                :img-item="item"
                :can-delete="imgcanDelete"
                @Document_Upload="CloudinaryUpload_UploadResult_DriversLicenceBack"
                @Document_Remove="Document_Remove"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </template>

      <el-form-item prop="passport">
        <el-row :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="24" :lg="16"
            :xl="16" class="label"
          >
            <span class="color-red">*</span> Do you have a Current Passport?
          </el-col>
          <el-col
            :xs="24" :sm="24" :md="24" :lg="8"
            :xl="8"
          >
            <el-radio-group v-model="ruleForm.passport" @change="OnShowPassportChange">
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </el-col>
        </el-row>
      </el-form-item>

      <template v-if="ruleForm.passport==='Yes'">
        <el-row :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12"
          >
            <el-form-item prop="passportNumber">
              <label class="label">
                Passport Number
                <el-input v-model="ruleForm.passportNumber" @input="applicantChange" />
              </label>
            </el-form-item>
          </el-col>
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12"
          >
            <el-form-item prop="passportCountryCode">
              <label class="label">
                Passport Country
                <GetAllGeoCountries
                  :disabled="allDisabled || passportOrderApplicantDocuments.length>0"
                  :defalut-value.sync="ruleForm.passportCountryCode"
                  :geo-countries="geoCountries"
                  @getCountryData="OnPassportCountryChange"
                />
              </label>
            </el-form-item>
          </el-col>
        </el-row>

        <div v-if="ruleForm.passportCountryCode">
          <p v-if="passportOrderApplicantDocuments.length<1" class="f-w-600"><span class="color-red">*</span> Please
            attach a legible copy of your Passport</p>
          <el-form-item prop="passportOrderApplicantDocuments">
            <OnlyUpload
              v-if="passportOrderApplicantDocuments.length<1 && imgcanDelete2"
              @Document_Upload="CloudinaryUpload_UploadResult_Passport"
            />
            <OnlyUpload
              v-for="item in passportOrderApplicantDocuments"
              :key="item.id"
              :img-item="item"
              :can-delete="getImgcanDelete(item)"
              @Document_Upload="CloudinaryUpload_UploadResult_Passport"
              @Document_Remove="Document_Remove"
            />
          </el-form-item>
        </div>
      </template>

      <template v-if="ruleForm.passport==='No'">
        <span class="label f-w-600"><span class="color-red">*</span> Please attach a legible copy of your Birth Certificate or Citizenship Certificate</span>
        <el-form-item v-if="birthCertificateOrderApplicantDocuments.length<1" prop="selectedCertificateType">
          <el-radio-group v-model="ruleForm.selectedCertificateType">
            <el-radio-button label="Birth">Birth Certificate</el-radio-button>
            <el-radio-button label="Citizenship">Citizenship Certificate</el-radio-button>
          </el-radio-group>
        </el-form-item>

        <div v-if="ruleForm.selectedCertificateType">
          <div v-if="ruleForm.selectedCertificateType==='Birth'">
            <OnlyUpload
              v-if="birthCertificateOrderApplicantDocuments.length<1 && imgcanDelete2"
              @Document_Upload="CloudinaryUpload_UploadResult_BirthCertificate"
            />
            <OnlyUpload
              v-for="item in birthCertificateOrderApplicantDocuments"
              :key="item.id"
              :img-item="item"
              :can-delete="getImgcanDelete(item)"
              @Document_Upload="CloudinaryUpload_UploadResult_BirthCertificate"
              @Document_Remove="Document_Remove"
            />
          </div>

          <div v-if="ruleForm.selectedCertificateType==='Citizenship'">
            <OnlyUpload
              v-if="birthCertificateOrderApplicantDocuments.length<1"
              @Document_Upload="CloudinaryUpload_UploadResult_CitizenshipCert"
            />
            <OnlyUpload
              v-for="item in birthCertificateOrderApplicantDocuments"
              :key="item.id"
              :img-item="item"
              :can-delete="getImgcanDelete(item)"
              @Document_Upload="CloudinaryUpload_UploadResult_CitizenshipCert"
              @Document_Remove="Document_Remove"
            />
          </div>
        </div>

        <div v-else>
          <OnlyUpload
            v-for="item in birthCertificateOrderApplicantDocuments"
            :key="item.id"
            :img-item="item"
            :can-delete="getImgcanDelete(item)"
            @Document_Upload="CloudinaryUpload_UploadResult_CitizenshipCert"
            @Document_Remove="Document_Remove"
          />
        </div>
      </template>

      <el-form-item prop="creditOrBankCard">
        <el-row :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="24" :lg="16"
            :xl="16" class="label"
          >
            <span class="color-red">*</span> Do you have a current credit/bank card?
          </el-col>
          <el-col
            :xs="24" :sm="24" :md="24" :lg="8"
            :xl="8"
          >
            <el-radio-group
              v-model="ruleForm.creditOrBankCard" :disabled="creditOrBankCardDisabled"
              @change="OnShowCreditOrBankCardChange"
            >
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </el-col>
        </el-row>
      </el-form-item>

      <template v-if="ruleForm.creditOrBankCard==='Yes'">

        <div v-if="creditOrBankCardOrderApplicantDocuments.length<1">
          <span v-if="!ruleForm.selectedCardType" class="label f-w-600"><span class="color-red">*</span> Please attach a legible copy of your Bank Card or Credit Card (Please cover card number)</span>
          <span v-if="ruleForm.selectedCardType === 'Credit'" class="label f-w-600"><span class="color-red">*</span> Please attach a legible copy of your Credit Card (Please cover card number)</span>
          <span v-if="ruleForm.selectedCardType === 'Bank'" class="label f-w-600"><span class="color-red">*</span> Please attach a legible copy of your Bank Card (Please cover card number)</span>
        </div>

        <el-form-item v-if="creditOrBankCardOrderApplicantDocuments.length<1" prop="selectedCardType">
          <el-radio-group v-model="ruleForm.selectedCardType">
            <el-radio-button label="Credit">Credit Card</el-radio-button>
            <el-radio-button label="Bank">Bank Card</el-radio-button>
          </el-radio-group>
        </el-form-item>

        <template v-if="ruleForm.selectedCardType || creditOrBankCardOrderApplicantDocuments.length>0">
          <OnlyUpload
            v-if="creditOrBankCardOrderApplicantDocuments.length<1 && imgcanDelete2"
            @Document_Upload="CloudinaryUpload_UploadResult_BankorCreditCard"
          />
          <span v-if="creditOrBankCardOrderApplicantDocuments.length>0" class="label f-w-600"><span class="color-red">*</span> Please attach a legible copy of your Bank Card or Credit Card (Please cover card number)</span>
          <OnlyUpload
            v-for="item in creditOrBankCardOrderApplicantDocuments"
            :key="item.id"
            :img-item="item"
            :can-delete="getImgcanDelete(item)"
            @Document_Upload="CloudinaryUpload_UploadResult_BankorCreditCard"
            @Document_Remove="Document_Remove"
          />
        </template>
      </template>

      <el-divider />

      <el-form-item prop="otherNames">
        <el-row :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="24" :lg="16"
            :xl="16" class="label"
          >
            <span class="color-red">*</span> Have you ever been known by any other names including an Alias, Maiden or
            Previous Names?
          </el-col>
          <el-col
            :xs="24" :sm="24" :md="24" :lg="8"
            :xl="8"
          >
            <el-radio-group v-model="ruleForm.otherNames" @change="OnShowOtherNamesChange">
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </el-col>
        </el-row>
      </el-form-item>

      <div v-if="ruleForm.otherNames==='Yes'">
        <PersonalDetailsItem ref="PersonalDetailsItem" :other-names="otherNames" />
      </div>
    </el-form>
    <el-button v-if="ContinueBtn" :loading="endAllRequests" type="primary" @click="Continue('ruleForm')">Save and
      Continue
    </el-button>
    <DriversLicenceTips
      :show.sync="showDriversLicenceTips"
      :drivers-licence-state="ruleForm.driversLicenceState"
    />
    <SingleNameTips
      :show.sync="showSingleNameTips"
      :is-single-name.sync="isSingleNameOnlyEnabled"
      :all-disabled.sync="allDisabled"
    />
  </div>
</template>

<style lang="scss" scoped>
.PersonalDetailsInternational {
  .label {
    margin: 0;
    font-size: 14px;
    color: #606266;
    line-height: 1.5;
  }
}

.dropdownClass .el-select-dropdown__item {
  width: 90vw !important;
  max-width: 500px;
}
</style>
