<script>
import { getNameApi } from '@/api/documentTypes'
export default {
  name: 'OnlyUpload',
  props: {
    imgList: {
      type: Array,
      default: () => []
    },
    imgItem: {
      type: Object,
      default: () => {}
    },
    canDelete: {
      type: Boolean,
      default: true
    },
    caption: {
      type: String,
      default: ''
    },
    iconColor: {
      type: String,
      default: 'green'
    },
    fileLoding: {
      type: Boolean,
      default: false
    },
    multipleFiles: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      source: null, // cancel upload
      fileName: '',
      loadingCover: false,
      typeList: ['png', 'jpeg', 'jpg', 'pdf', 'heic'],
      fileTypePass: true,
      notPassFileName: '',
      notPassFileSize: '',
      removeLoding: false,
      processLen: 0,
      maxFileSize: 20971520,
      uploadTimer: null, // store upload timer
      progressInterval: null, // store progress bar timer
      currentRemovingItem: null // current item being removed
    }
  },
  computed: {
    defaultImg: {
      get() {
        return this.fileLoding
      },
      set(val) {
        this.$emit('update:fileLoding', val)
      }
    },
    // Determine whether to display the upload control
    shouldShowUpload() {
      // If uploading, do not display the upload control
      if (this.defaultImg || this.loadingCover || this.processLen > 0) {
        return false
      }

      // Always display in multiple files mode (unless uploading)
      if (this.multipleFiles) {
        return true
      }

      // In single file mode, only display when there is no file
      if (this.imgItem && Object.keys(this.imgItem).length > 0) {
        return false
      }

      if (this.imgList && this.imgList.length > 0) {
        return false
      }

      return true
    }
  },
  watch: {
    'imgItem.thumbUrl': {
      handler(data) {
        if (data) {
          this.defaultImg = ''
          this.resetUploadState() // Reset upload status when imgItem updates
        }
      },
      immediate: true,
      deep: true
    },
    imgList: {
      handler(newList, oldList) {
        // If the list length increases, it means the upload was successful
        if (newList && oldList && newList.length > oldList.length) {
          this.resetUploadState()
        }
      },
      deep: true
    },
    defaultImg(newValue, oldValue) {
      if (!newValue) {
        // false  close loading
        this.loadingCover = ''
      }
    }
  },
  created() {
    this.getDocumentName()
  },
  beforeDestroy() {
    // Clear any existing timers
    if (this.uploadTimer) {
      clearTimeout(this.uploadTimer)
    }
    if (this.progressInterval) {
      clearInterval(this.progressInterval)
    }
  },
  methods: {
    uploadChange(res, file) {
      if (!(file[0].size > this.maxFileSize) && !this.fileTypeVerdict(file[0])) {
        this.loadingCover = URL.createObjectURL(res.raw)
      }
    },
    uploadFile(file) {
      this.notPassFileName = file.file.name
      if (file.file.size > this.maxFileSize) {
        this.fileTypePass = false
      } else {
        this.notPassFileSize = (file.file.size / 1024).toFixed(2)
        if (this.fileTypeVerdict(file.file)) {
          this.fileTypePass = false
        } else {
          // Clear any previously existing timers
          if (this.progressInterval) {
            clearInterval(this.progressInterval)
          }

          // Immediately set progress bar to 1% to indicate upload has started
          this.processLen = 1

          // Set up progress bar
          this.progressInterval = setInterval(() => {
            if (this.processLen >= 99) {
              clearInterval(this.progressInterval)
              this.progressInterval = null
              return
            }
            this.processLen += 1
          }, 80)

          // Send upload event directly, let parent component decide how to handle it
          this.$emit('Document_Upload', file.file)

          // Clear any previously existing upload timer
          if (this.uploadTimer) {
            clearTimeout(this.uploadTimer)
          }

          // Set a backup timer in case the upload event doesn't trigger callback correctly
          this.uploadTimer = setTimeout(() => {
            if (this.processLen > 0) {
              this.resetUploadState()
            }
            this.uploadTimer = null
          }, 15000) // Force reset after 15 seconds if not completed
        }
      }
    },

    fileTypeVerdict(file) { // false--file type not allowed to upload
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
      const isSuffix = this.typeList.indexOf(fileSuffix) === -1
      return isSuffix
    },

    removeDocuments(imgItem) {
      // Set the current item being removed
      this.currentRemovingItem = imgItem
      this.removeLoding = true

      // Send remove event
      this.$emit('Document_Remove', imgItem)

      // If in single file mode, reset the file name after deletion
      if (!this.multipleFiles) {
        this.fileName = ''
        // If passed through imgList, can reset state directly
        if (this.imgList.length > 0) {
          // Wait for DOM update
          this.$nextTick(() => {
            // Only reset file name, don't reset loading status
            this.fileTypePass = true
            this.notPassFileName = ''
            this.notPassFileSize = ''
            this.processLen = 0
            this.loadingCover = false

            // Clear timers
            if (this.progressInterval) {
              clearInterval(this.progressInterval)
              this.progressInterval = null
            }

            if (this.uploadTimer) {
              clearTimeout(this.uploadTimer)
              this.uploadTimer = null
            }
          })
        }
      }

      // No longer set a timer to cancel the loading effect
      // Let the loading effect display until the component is destroyed
    },
    cancelUpload() {
      if (this.source) {
        this.source.cancel('cnacel upload')
        this.show = false
      }
    },
    openImg(url) {
      window.open(url)
    },
    async getDocumentName() {
      if (!this.caption) {
        if (this.imgItem && this.imgItem.documentTypeId) {
          await getNameApi({ id: this.imgItem.documentTypeId }).then(res => {
            this.fileName = res.data.name
          })
        }
      } else {
        this.fileName = this.caption
      }
    },
    uploadProgress(event) {
      console.log(event)
    },
    getItemCaption(item) {
      // If there's a caption property, use it first
      if (this.caption) {
        return this.caption
      }

      // Try to get the name from the item
      if (item.documentTypeId) {
        // If there's a documentTypeId, may need to get the name via API
        return this.fileName || item.name || 'Untitled Document'
      }

      // Directly return possible name properties
      return item.name || (item.file && item.file.name) || 'Untitled Document'
    },
    resetUploadState() {
      // Implement logic to reset upload status
      this.fileTypePass = true
      this.notPassFileName = ''
      this.notPassFileSize = ''
      this.processLen = 0
      this.loadingCover = false

      // Clear timers
      if (this.progressInterval) {
        clearInterval(this.progressInterval)
        this.progressInterval = null
      }

      if (this.uploadTimer) {
        clearTimeout(this.uploadTimer)
        this.uploadTimer = null
      }
    }
  }
}
</script>

<template>
  <div class="documentGalleryUpload">
    <!-- Upload status display -->
    <el-row v-if="defaultImg || loadingCover || processLen > 0">
      <el-col>
        <div class="img-box">
          <img
            v-if="loadingCover"
            :src="loadingCover" alt="" style="max-width: 200px"
          >
          <div v-else class="upload-placeholder">
            <i class="el-icon-upload" style="font-size: 48px; color: #909399;" />
          </div>
          <div class="upload-test">
            <div>
              <p class="color-white">{{ notPassFileName || 'File uploading' }}</p>
              <p class="color-white">{{ notPassFileSize ? `${notPassFileSize}KB` : '' }}</p>
            </div>
            <div>
              <p class="color-white">Uploading {{ processLen }}% <i class="fa fa-circle-o-notch fa-pulse" /></p>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>

    <!-- Upload control - display based on multipleFiles and existing files -->
    <el-row v-else-if="shouldShowUpload">
      <el-col>
        <el-upload
          v-if="fileTypePass"
          ref="upload"
          class="upload-demo"
          drag
          action="#"
          :http-request="uploadFile"
          :before-remove="cancelUpload"
          :show-file-list="false"
          :on-change="uploadChange"
          :on-progress="uploadProgress"
          :multiple="multipleFiles"
        >
          <div style="display: flex;flex-direction: column;height: 80px;justify-content: center;">
            <span style="height: 20px; line-height: 20px; font-size: 13px; font-weight: bold;">
              Drag & Drop your {{ multipleFiles ? 'files' : 'file' }} or<span class="browse" style="margin-left: 5px;">Browser</span>
            </span>
            <span style="height: 20px; line-height: 18px; font-size: 13px;">Max file size: 20MB. Acceptable formats: jpeg, jpg, png, pdf, or heic.</span>
          </div>
        </el-upload>

        <div v-else>
          <div class="not-pass-box">
            <div class="not-pass-content">
              <div class="content1"><i class="el-icon-error" @click="fileTypePass = !fileTypePass" /></div>
              <div class="content2" />
              <div class="content3">
                <p class="color-white">File size exceed 20MB or file type is invalid</p>
                <p class="color-pink">Expects jpeg, jpg, png, pdf, or heic</p>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>

    <!-- Display uploaded documents -->
    <div v-if="imgItem || (imgList && imgList.length > 0)" class="DocumentGalleryBox">
      <!-- Single file mode or when passed through imgItem -->
      <div
        v-if="imgItem"
        v-loading="removeLoding && currentRemovingItem === imgItem"
        element-loading-text="Deleting..."
        element-loading-background="rgba(255, 255, 255, 0.9)"
        class="DocumentGalleryItem"
      >
        <div class="img">
          <el-image
            alt="document"
            style="width: 140px; height: 200px"
            :src="imgItem.thumbUrl"
            fit="cover"
            @click="openImg(imgItem.docurl)"
          />
          <i
            v-if="canDelete"
            class="el-icon-error"
            :style="{color:iconColor==='red'?'red':iconColor}"
            @click="removeDocuments(imgItem)"
          />
        </div>
        <div class="msg">{{ fileName }}</div>
      </div>

      <!-- Multiple files passed through imgList -->
      <div
        v-for="(item, index) in imgList"
        :key="index"
        v-loading="removeLoding && currentRemovingItem === item"
        element-loading-text="Deleting..."
        element-loading-background="rgba(255, 255, 255, 0.9)"
        class="DocumentGalleryItem"
      >
        <div class="img">
          <el-image
            alt="document"
            style="width: 140px; height: 200px"
            :src="item.thumbUrl"
            fit="cover"
            @click="openImg(item.docurl)"
          />
          <i
            v-if="canDelete"
            class="el-icon-error"
            :style="{color:iconColor==='red'?'red':iconColor}"
            @click="removeDocuments(item)"
          />
        </div>
        <div class="msg">{{ getItemCaption(item) }}</div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.img-box {
  border-radius: 5px;
  width: 95%;
  background: #dee2e6;
  padding: 4px;
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  min-height: 100px;

  .upload-test {
    border-radius: 5px;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    padding: 4px 0;
    background: linear-gradient(black, transparent);
    z-index: 10000;
    display: flex;
    justify-content: space-between;

    p {
      font-size: 12px;
      line-height: 1.5;
      margin: 0;
      padding: 0 4px;
    }
  }
}

.upload-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
}

.not-pass-box {
  width: 100%;
  margin: 10px 0;
  height: 80px;
  line-height: 80px;
  background-color: #f1f0ef;
  border-radius: 4px;
  padding: 10px 8px;
  box-sizing: border-box;

  .not-pass-content {
    background-color: #c44f47;
    border-radius: 4px;
    height: 60px;
    padding: 4px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      line-height: 1.5;
      margin: 0;
    }

    .content1 {
      font-size: 30px;

      .el-icon-error {
        cursor: pointer;
      }
    }

    .content2 {
      width: 20%;

      p {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .content3 {
      text-align: right;
    }
  }
}

.color-white {
  color: #fff;
}

.color-pink {
  color: #db918c;
}

.documentGalleryUpload {
  ::v-deep .el-upload {
    width: 100%;
  }

  ::v-deep .el-upload-dragger {
    width: 100%;
    height: 80px;
    border: 1px solid #d9d9d9;
    background: #f1f0ef;
  }
}

.upload-demo {
  margin: 10px 0;
  height: 80px;
  line-height: 80px;
  font-size: 14px;

  .browse {
    text-decoration: underline;
  }
}

.DocumentGalleryBox {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 10px;
  padding-top: 10px;
  position: relative;
}

.uploaded-title {
  width: 100%;
  margin-top: 0;
  margin-bottom: 10px;
  color: #606266;
  font-size: 14px;
  font-weight: normal;
}

.DocumentGalleryItem {
  width: 156px;
  height: 242px;
  background: #fff;
  margin: 0 20px 10px 0;
  border: 1px solid #dee2e6;
  border-radius: 4px;
}

.img {
  width: 140px;
  height: 205px;
  padding: 8px;
  position: relative;
  cursor: pointer;

  .el-icon-error {
    font-size: 25px;
    position: absolute;
    right: 8px;
  }
}

.msg {
  padding: 0 8px;
  line-height: 1.5;
  font-size: 12px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.upload {
  width: 140px;
  padding: 10px;
  outline: 1px solid red;
  text-align: center;

  .hight-light {
    color: #2d74af;
  }

  .action {
    font-size: 12px;
    color: #272b30;
    margin: 10px auto 0;
  }
}
</style>
