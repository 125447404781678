<script>
import _ from 'lodash'
import global from '@/utils/global'
import { mapState } from 'vuex'
import { preparationApi, saveFormDataApi, continueApi } from '@/api/MedicalDeclaration'
// import PubSubHub from '@/views/Edit/PubSubHub.vue'
export default {
  name: 'MedicalDeclarationView',
  inject: ['SaveFormDataSetSession', 'ContinueSetSession'],
  data() {
    return {
      currentFormSection: {},
      ruleForm: {
        hasConditionAffectingRole: '',
        hasConditionAffectingAttendence: '',
        roleConditionDetail: '',
        attendenceConditionDetail: '',
        otherMedicalInformation: ''
      },
      rules: {
        hasConditionAffectingRole: [{ required: true, message: 'Please select a value', trigger: 'change' }],
        hasConditionAffectingAttendence: [{ required: true, message: 'Please select a value', trigger: 'change' }],
        roleConditionDetail: [{ required: true, message: 'required field!', trigger: 'blur' }],
        attendenceConditionDetail: [{ required: true, message: 'required field!', trigger: 'blur' }]
      },
      allLoading: false
    }
  },
  computed: {
    ...mapState({
      device: state => state.setting.device,
      introduction: state => state.section.introduction,
      sectionTitle: state => state.section.sectionTitle,
      preparation: state => state.preparation.preparation,
      endAllRequests: state => state.portCount.endAllRequests
    }),
    isMobile() {
      return this.device === 'mobile'
    },
    Session() {
      return this.preparation.session
    },
    ApplicantId() {
      return this.preparation.session.current.applicantId
    },
    allDisabled() {
      // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return !(
        !this.Session.current.isSubmitted ||
        this.Session.current.formSection.completePercent < 100 ||
        this.Session.current.isAdmin
      )
    },
    ContinueBtn() {
    // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return (!this.Session.current.isSubmitted ||
     this.Session.current.formSection.completePercent < 100 ||
     this.Session.current.isAdmin)
    }
  },
  created() {
    this.Preparation()
  },
  methods: {
    async Preparation() {
      // if (PubSubHub !== null && PubSubHub.client.state === 'Connected') {
      //   await PubSubHub.client.send('UpdateApplicantWebblock', 'MedicalDeclaration')
      // }
      this.allLoading = true
      const params = {
        session: this.Session,
        ApplicantId: this.ApplicantId
      }
      await preparationApi(params).then(res => {
        const { data } = res
        this.currentFormSection = data.currentFormSection
        this.ruleForm = data.applicantMedicalDeclarationDto

        this.ruleForm.hasConditionAffectingRole = data.hasConditionAffectingRole
        this.ruleForm.hasConditionAffectingAttendence = data.hasConditionAffectingAttendence
        this.allLoading = false
      }).catch(() => {
        this.allLoading = false
      })
    },

    Continue(formName) {
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          const params = {
            session: this.Session,
            applicantId: this.ApplicantId,
            currentFormSection: this.currentFormSection,
            hasConditionAffectingRole: this.ruleForm.hasConditionAffectingRole,
            hasConditionAffectingAttendence: this.ruleForm.hasConditionAffectingAttendence,
            applicantDeclaration: this.ruleForm
          }
          delete params.applicantDeclaration.hasConditionAffectingRole
          delete params.applicantDeclaration.hasConditionAffectingAttendence
          await continueApi(params).then(res => {
            this.ContinueSetSession(res.data.session)
          })
        }
      })
    },

    SaveFormTempData: _.debounce(
      function() {
        if (this.ruleForm.hasConditionAffectingRole === 'No') {
          this.ruleForm.roleConditionDetail = ''
        }
        if (this.ruleForm.hasConditionAffectingAttendence === 'No') {
          this.ruleForm.attendenceConditionDetail = ''
        }
        const params = {
          session: this.Session,
          applicantId: this.ApplicantId,
          formData: {
            hasConditionAffectingRole: this.ruleForm.hasConditionAffectingRole,
            hasConditionAffectingAttendence: this.ruleForm.hasConditionAffectingAttendence,
            applicantDeclaration: this.ruleForm
          }
        }
        saveFormDataApi(params).then(res => {
          if (res.data && res.data.session) { this.SaveFormDataSetSession(res.data.session) }
        })
      }, global.debounceTime)
  }
}
</script>
<template>
  <div v-loading="allLoading" class="MedicalDeclarationView">
    <div class="section-header">{{ sectionTitle }}</div>
    <div class="mb-10 f-s-14" v-html="introduction" />
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      class="demo-ruleForm"
      :disabled="allDisabled"
    >
      <el-form-item
        prop="hasConditionAffectingRole"
      >
        <el-row :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="24" :lg="16"
            :xl="16" class="label"
          >
            <span class="color-red">*</span> Do you have any pre-existing illnesses, injuries or medical conditions*
            <el-tooltip class="item" effect="dark" content="* Please note: Pre-existing illnesses, injuries or medical conditions may include but not limited to: Epilepsy, fainting fits, blackouts, diabetes, asthma or any other respiratory/lung condition, heart condition, repetitive strain type injury, neck/back injury, loss of sight, hearing impairment, cerebral vascular accident, mental or psychiatric conditions including depression, ADHD, ASD or anxiety, any chronic disease or illness" placement="top">
              <i class="el-icon-info" />
            </el-tooltip>
            that may impact your ability to undertake the duties of the role you have applied for?
          </el-col>
          <el-col
            :xs="24" :sm="24" :md="24" :lg="8"
            :xl="8"
          >
            <el-radio-group v-model="ruleForm.hasConditionAffectingRole" @change="SaveFormTempData">
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </el-col>
        </el-row>
      </el-form-item>

      <el-row v-if="ruleForm.hasConditionAffectingRole === 'Yes'" :gutter="20">
        <el-col
          :xs="24" :sm="24" :md="24" :lg="16"
          :xl="16" class="label"
        >
          <span class="color-red">*</span> Please provide further details of the condition in the space provided below including details of any treatment requirements and any adjustments that may be required in the workplace to accommodate that condition.
        </el-col>
        <el-col
          :xs="24" :sm="24" :md="24" :lg="8"
          :xl="8"
        >
          <el-form-item
            prop="roleConditionDetail"
          >
            <el-input
              v-model="ruleForm.roleConditionDetail"
              type="textarea"
              maxlength="1000"
              @input="SaveFormTempData"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item
        prop="hasConditionAffectingAttendence"
      >
        <el-row :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="24" :lg="16"
            :xl="16" class="label"
          >
            <span class="color-red">*</span> Do you have any pre-existing illnesses, injuries or medical conditions that may impact your ability to maintain your attendance at work?
          </el-col>
          <el-col
            :xs="24" :sm="24" :md="24" :lg="8"
            :xl="8"
          >
            <el-radio-group v-model="ruleForm.hasConditionAffectingAttendence" @change="SaveFormTempData">
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </el-col>
        </el-row>
      </el-form-item>

      <el-row v-if="ruleForm.hasConditionAffectingAttendence === 'Yes'" :gutter="20">
        <el-col
          :xs="24" :sm="24" :md="24" :lg="16"
          :xl="16" class="label"
        >
          <span class="color-red">*</span> Please provide further details of the condition in the space provided below including details of any treatment requirements and any adjustments that may be required in the workplace to accommodate that condition.
        </el-col>
        <el-col
          :xs="24" :sm="24" :md="24" :lg="8"
          :xl="8"
        >
          <el-form-item
            prop="attendenceConditionDetail"
          >
            <el-input
              v-model="ruleForm.attendenceConditionDetail"
              type="textarea"
              maxlength="1000"
              @input="SaveFormTempData"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col
          :xs="24" :sm="24" :md="24" :lg="16"
          :xl="16" class="label"
        >
          Please provide any further information or workplace modifications you believe may be necessary for you to perform the duties of the role applied for
        </el-col>
        <el-col
          :xs="24" :sm="24" :md="24" :lg="8"
          :xl="8"
        >
          <el-form-item
            prop="otherMedicalInformation"
          >
            <el-input
              v-model="ruleForm.otherMedicalInformation"
              type="textarea"
              maxlength="1000"
              @input="SaveFormTempData"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <!-- <el-button type="primary" @click="SaveFormTempData('ruleForm')">Save form data</el-button> -->
      <el-button v-if="ContinueBtn" :loading="endAllRequests" type="primary" @click="Continue('ruleForm')">Save and Continue</el-button>
    </el-form>
  </div>
</template>
<style lang="scss" >
.MedicalDeclarationView {
  .label {
    line-height: 1.5;
    font-size: 14px;
    color: #606266;
  }
  .el-form-item__label {
    line-height: 18px;
  }
}
</style>
