<script>
import * as VuePhoneNumberInput from './vue-phone-number-input/vue-phone-number-input.umd'
import './vue-phone-number-input/vue-phone-number-input.css'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
export default {
  name: 'PhoneNumberInput',
  components: { VuePhoneNumberInput },
  props: {
    number: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
      default: () => false
    },
    error: {
      // Validation failed
      type: Boolean,
      default: false
    },
    disabled: {
      // Disabled state
      type: Boolean,
      default: false
    },
    clearable: {
      // Whether the input can be cleared
      type: Boolean,
      default: false
    },
    validColor: {
      // Color when validation passes
      type: String,
      default: '#dddfe6'
    },
    color: {
      // Default appearance
      type: String,
      default: 'dodgerblue'
    },
    translations: {
      // Custom text
      type: Object,
      default: function() {
        return {
          countrySelectorLabel: '',
          countrySelectorError: '',
          phoneNumberLabel: '',
          example: ''
        }
      }
    },
    noCountrySelector: {
      // Whether to disable the country selector, enabled by default
      type: Boolean,
      default: false
    },
    defaultCountryCode: {
      type: String,
      default: 'AU'
    },
    preferredCountries: {
      // Default countries to display at the top, array format, value is a set of country codes, e.g. China "CN"
      type: Array,
      default: () => ['AU', 'NZ']
    },
    borderRadius: {
      type: Number,
      default: 0
    },
    showCodeOnList: {
      // Whether to display the international code in the country selector
      type: Boolean,
      default: false
    },
    noFlags: {
      // Whether to display the flag of the country in the country selector
      type: Boolean,
      default: false
    },
    noExample: {
      // Whether to display input example
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      phone: ''
    }
  },
  mounted() {
    this.setDefaults()
  },
  methods: {
    setDefaults() {
      const parsedPhoneNumber = parsePhoneNumberFromString(this.number, 'NO')
      // console.log(parsedPhoneNumber)
      if (parsedPhoneNumber) {
        // console.log(this.$refs.vuePhoneNumberInput)
        // this.$nextTick(() => {
        // this.$refs.vuePhoneNumberInput.emitValues({
        //   countryCode: parsedPhoneNumber.country,
        //   phoneNumber: parsedPhoneNumber.nationalNumber
        // })
        this.$refs.vuePhoneNumberInput.countryCode = parsedPhoneNumber.country
        this.$refs.vuePhoneNumberInput.phoneNumber = parsedPhoneNumber.nationalNumber

        //   this.$forceUpdate()
        // })
      }
    },
    blur(formName) {
      this.$emit('phoneBlur', '')
    },
    update(allValue) {
      if (allValue.formatNational) {
        this.$nextTick(() => {
          this.phone = allValue.formatNational
        })
      } else {
        this.$nextTick(() => {
          this.phone = allValue.phoneNumber
        })
      }
      this.$emit('update', allValue)
    }
  }
}
</script>

<template>
  <div>
    <VuePhoneNumberInput
      ref="vuePhoneNumberInput"
      v-model="phone"
      :error="error"
      :valid-color="validColor"
      :color="color"
      :no-country-selector="noCountrySelector"
      :default-country-code="defaultCountryCode"
      :preferred-countries="preferredCountries"
      :disabled="disabled"
      :clearable="clearable"
      :show-code-on-list="showCodeOnList"
      :no-flags="noFlags"
      :border-radius="borderRadius"
      :no-example="noExample"
      :translations="translations"
      aria-label="Phone number"
      @update="update"
      @phone-number-blur="blur"
    />
  </div>

</template>

<style lang="scss" scoped>
:deep(.country-selector) {
  cursor: pointer;
  position: relative;
  pointer-events: auto;
  
  .country-selector__input {
    cursor: pointer;
    pointer-events: none;
  }
  
  .country-selector__toggle {
    cursor: pointer;
    pointer-events: none;
  }
  
  .country-selector__label {
    cursor: pointer;
    pointer-events: none;
  }

  .country-selector__country-flag {
    cursor: pointer;
    pointer-events: none;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
  }
}
</style>
