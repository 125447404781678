var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.allLoading,
          expression: "allLoading",
        },
      ],
      staticClass: "MedicalDeclarationView",
    },
    [
      _c("div", { staticClass: "section-header" }, [
        _vm._v(_vm._s(_vm.sectionTitle)),
      ]),
      _c("div", {
        staticClass: "mb-10 f-s-14",
        domProps: { innerHTML: _vm._s(_vm.introduction) },
      }),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            disabled: _vm.allDisabled,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "hasConditionAffectingRole" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "label",
                      attrs: { xs: 24, sm: 24, md: 24, lg: 16, xl: 16 },
                    },
                    [
                      _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
                      _vm._v(
                        " Do you have any pre-existing illnesses, injuries or medical conditions* "
                      ),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content:
                              "* Please note: Pre-existing illnesses, injuries or medical conditions may include but not limited to: Epilepsy, fainting fits, blackouts, diabetes, asthma or any other respiratory/lung condition, heart condition, repetitive strain type injury, neck/back injury, loss of sight, hearing impairment, cerebral vascular accident, mental or psychiatric conditions including depression, ADHD, ASD or anxiety, any chronic disease or illness",
                            placement: "top",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-info" })]
                      ),
                      _vm._v(
                        " that may impact your ability to undertake the duties of the role you have applied for? "
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 24, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { change: _vm.SaveFormTempData },
                          model: {
                            value: _vm.ruleForm.hasConditionAffectingRole,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "hasConditionAffectingRole",
                                $$v
                              )
                            },
                            expression: "ruleForm.hasConditionAffectingRole",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "Yes" } }),
                          _c("el-radio", { attrs: { label: "No" } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.ruleForm.hasConditionAffectingRole === "Yes"
            ? _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "label",
                      attrs: { xs: 24, sm: 24, md: 24, lg: 16, xl: 16 },
                    },
                    [
                      _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
                      _vm._v(
                        " Please provide further details of the condition in the space provided below including details of any treatment requirements and any adjustments that may be required in the workplace to accommodate that condition. "
                      ),
                    ]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 24, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "roleConditionDetail" } },
                        [
                          _c("el-input", {
                            attrs: { type: "textarea", maxlength: "1000" },
                            on: { input: _vm.SaveFormTempData },
                            model: {
                              value: _vm.ruleForm.roleConditionDetail,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "roleConditionDetail",
                                  $$v
                                )
                              },
                              expression: "ruleForm.roleConditionDetail",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { prop: "hasConditionAffectingAttendence" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "label",
                      attrs: { xs: 24, sm: 24, md: 24, lg: 16, xl: 16 },
                    },
                    [
                      _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
                      _vm._v(
                        " Do you have any pre-existing illnesses, injuries or medical conditions that may impact your ability to maintain your attendance at work? "
                      ),
                    ]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 24, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { change: _vm.SaveFormTempData },
                          model: {
                            value: _vm.ruleForm.hasConditionAffectingAttendence,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "hasConditionAffectingAttendence",
                                $$v
                              )
                            },
                            expression:
                              "ruleForm.hasConditionAffectingAttendence",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "Yes" } }),
                          _c("el-radio", { attrs: { label: "No" } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.ruleForm.hasConditionAffectingAttendence === "Yes"
            ? _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "label",
                      attrs: { xs: 24, sm: 24, md: 24, lg: 16, xl: 16 },
                    },
                    [
                      _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
                      _vm._v(
                        " Please provide further details of the condition in the space provided below including details of any treatment requirements and any adjustments that may be required in the workplace to accommodate that condition. "
                      ),
                    ]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 24, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "attendenceConditionDetail" } },
                        [
                          _c("el-input", {
                            attrs: { type: "textarea", maxlength: "1000" },
                            on: { input: _vm.SaveFormTempData },
                            model: {
                              value: _vm.ruleForm.attendenceConditionDetail,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "attendenceConditionDetail",
                                  $$v
                                )
                              },
                              expression: "ruleForm.attendenceConditionDetail",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  staticClass: "label",
                  attrs: { xs: 24, sm: 24, md: 24, lg: 16, xl: 16 },
                },
                [
                  _vm._v(
                    " Please provide any further information or workplace modifications you believe may be necessary for you to perform the duties of the role applied for "
                  ),
                ]
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 24, lg: 8, xl: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "otherMedicalInformation" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea", maxlength: "1000" },
                        on: { input: _vm.SaveFormTempData },
                        model: {
                          value: _vm.ruleForm.otherMedicalInformation,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.ruleForm,
                              "otherMedicalInformation",
                              $$v
                            )
                          },
                          expression: "ruleForm.otherMedicalInformation",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.ContinueBtn
            ? _c(
                "el-button",
                {
                  attrs: { loading: _vm.endAllRequests, type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.Continue("ruleForm")
                    },
                  },
                },
                [_vm._v("Save and Continue")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }